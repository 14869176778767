.card-buong {
    &:hover {
        border-radius: 5px;
        background-color: #e9e9e9;
        cursor: pointer;
    }

    .custom-progress {
        height: 30px;
        flex-grow: 1;

        .progress-bar {
            background-color: #3C78D8 !important;
        }
    }
}