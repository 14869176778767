.max-h-100 {
	max-height: 100%;
}

.oct-image-lightbox {
	.modal-body,
	.modal-header,
	.modal-footer {
		background: linear-gradient(rgba(30, 30, 30, 0.9), #000 1810%);
	}

	.modal-header {
		border: none;
		padding: 0 !important;
	}

	.modal-body {
		padding: 0 16px !important;
	}

	&-thumbnail {
		min-width: 120px;
		max-width: 150px;
		height: 90px;
		margin: 0 4px;
		border-radius: 1px;
		cursor: pointer;
		opacity: 0.5;
		transition: opacity 0.15s ease;

		&:hover {
			opacity: 1;
		}

		&--active {
			box-shadow: 0px 0px 0px 3px #fff;
			opacity: 1;
		}
	}
}
