.custom-icon-button {
  padding: 2px 5px;
  border-radius: 13px;
  transition: transform 0.1s ease;
  &:hover {
    transform: scale(1.2);
  }
  i {
    line-height: unset;
    font-size: 1.8rem;
    cursor: pointer;
  }
}

.button-tooltip {
  .tooltip-inner {
    font-size: 0.88rem;
    font-weight: 500;
    background-color: #999999;
    color: #ffffff;
  }
}