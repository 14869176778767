.font-size-12px {
  font-size: 12px !important;
}

.padding-3-20 {
  padding: 3px 20px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.w-816 {
  width: 816px;
}

.w-16_8 {
  width: 16.8%;
}

.fs-14 {
  font-size: 14px !important;
}

.dsDvCDHA {
  .fw-bold td {
    font-weight: normal !important;
  }
}

.ckeditor-custom.modal-thuc-hien-cdha {
  .ck .ck-editor__main {
    height: calc(100vh - 250px) !important;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }
}

.img-group {
  max-height: 210px;
  overflow-y: auto;
  max-width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  // justify-content: center;
}

.item-img {
  width: 49%;
  justify-content: center;
  align-items: center;
}

.grid-6-6 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 2px;
  margin-bottom: 2px;
}

.text-visit-infor {
  color: #0D47A1;
}

.image-capture-preview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.menu-button-cdha .dropdown-toggle-icon {
  margin: 0;
  padding: 5px 2px;
  font-size: 1.1rem;
}