.oct-image-action-button {
	border-radius: 0;
	transition: background 0.15s ease-in-out;

	i.bi {
		font-size: 1.2rem;
	}

	i.bi::before {
		font-weight: 600 !important;
	}

	&:hover {
		background-color: #46495b;
	}
}
