.card-giuong {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        border: 1px solid #aaaaaa;
    }

    .icon-giuong > svg {
        width: 75px;
        height: 50px;
    }
}