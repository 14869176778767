.image-editor-tool {
	background-color: rgb(33, 34, 35) !important;
	border-radius: 0;
	transition: background 0.25s ease;
    padding: 8px 16px !important;

	i {
		font-size: 1.3rem;
        padding: 0;
	}

	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&:hover {
		background-color: var(--bs-info) !important;
	}
}

.image-editor-cropper {
	.cropper-point {
		width: 12px;
		height: 12px;
	}

	.cropper-container {
		height: 100% !important;
	}
}