$border-style: 1px solid rgba(5, 5, 5, 0.06);

.oct-date-picker-wrapper {
    .react-datepicker__input-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .react-datepicker__calendar-icon {
            cursor: pointer;
            color: var(--bs-btn-primary);
        }

        input {
            padding-left: 4px;
        }
    }
}

.oct-date-picker-popper {
    z-index: 100000 !important;

    .react-datepicker {
        display: flex;
        border: none;
        border-radius: 8px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),     
                    0 3px 6px -4px rgba(0, 0, 0, 0.12),      
                    0 9px 28px 8px rgba(0, 0, 0, 0.05);
        
        .react-datepicker__header {
            background-color: #ffffff;
            border-bottom: $border-style;
        }

        .react-datepicker__day {
            margin: 2px;
            width: 2rem;
            line-height: 1.8rem;
        }

        .react-datepicker__day-name {
            margin: 0 4px;
        }

        .react-datepicker__day-names {
            padding-top: 4px;
        }

        .react-datepicker__month {
            padding: 8px;
        }

        .react-datepicker__time-container {
            border-left: $border-style;
        }

        .react-datepicker__header--time {
            background-color: #ffffff;
            border-bottom: $border-style;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50px;
        }

        .react-datepicker__time-container 
        .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li {
            border-radius: 2px;
        }

        .react-datepicker__day--selected,
        .react-datepicker__time-list-item--selected {
            background-color: var(--color-header-table-custom) !important;
        }
    }
}

