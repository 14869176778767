@import "../../styles/variable";

.input-autocomplete-popover {
    .popover-arrow {
        display: none;
    }
}

.input-autocomplete-option {
    color: #000;
    border: none;

    &:hover, &.selected {
        background-color: $bg-btn-custom;
        color: #fff;
    }   
}