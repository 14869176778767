.font-size-12px {
  font-size: 12px !important;
}

.padding-3-20 {
  padding: 3px 20px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.width-144px {
  width: 144px !important;
}

.w-816 {
  width: 816px;
}

.w-16_8 {
  width: 16.8%;
}

.fs-14 {
  font-size: 14px !important;
}

.dsDvchuyen-khoa {
  .fw-bold td {
    font-weight: normal !important;
  }
}

.ckeditor-custom.modal-thuc-hien-chuyen-khoa {
  .ck .ck-editor__main {
    height: calc(100vh - 284px) !important;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }
}

.modal-chuyen-khoa {
  .modal-content {
    overflow: hidden !important;
    .dialog-body.dialog-body-PTTT {
      overflow: auto;
      height: calc(100vh - 184px) !important;
    }
  }
}

.img-group {
  max-height: 210px;
  overflow-y: auto;
  max-width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  // justify-content: center;
}

.item-img {
  width: 49%;
  justify-content: center;
  align-items: center;
}

.grid-6-6 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 2px;
  margin-bottom: 2px;
}

.grid-3-9 {
  display: grid;
  grid-template-columns: 2.9fr 9.1fr;
  gap: 2px;
  margin-bottom: 2px;
}

.grid-1_5-11_5 {
  display: grid;
  grid-template-columns: 1.5fr 11.5fr;
  gap: 2px;
  margin-bottom: 2px;
}

.grid-2_9-9_4 {
  display: grid;
  grid-template-columns: 2.9fr 9.4fr;
  gap: 2px;
  margin-bottom: 2px;
}

.sub-title-kq {
  color: #1A5E83;
}

.btn-outline-pttt {
  color: #1A5E83 !important;
  border-color: #1A5E83 !important;
}

.fs-16 {
  font-size: 16px;
}

.item-input {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 2px;
  margin: 2px 0;
}

.grid-5_9-6_1 {
  display: grid;
  grid-template-columns: 5.9fr 6.1fr;
  gap: 2px;
  margin: 2px 0;
}

.drawable-tool-item {
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    border: 1px solid var(--kt-input-border-color);
    flex-basis: 100px;
    flex-grow: 1;
    
    &:hover {
      background: #eee;
    }

    &.selected {
      border-color: var(--ck-color-base-active);
    }
}
