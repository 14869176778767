.card-item-action {
	background-color: transparent !important;

	&--icon {
		color: var(--bs-primary) !important;
		font-size: 1.3rem !important;
		transition: color 0.2s ease-in;

		&:hover {
			color: var(--bs-blue) !important;
		}
	}
}
