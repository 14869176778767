.header {
    padding: 20px 50px 20px 50px;
    border-bottom: 1px solid #ccc;
}

.main {
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}

.container {
    flex-direction: column;
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-group {
    max-height: 100%;
    width: 85%;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn-homepage {
    padding: 0 !important;
    margin-bottom: 15px;
    gap: 10px;
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 35px;
}

.item-button {
    width: 17%;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.title-button {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: normal;
}

.btn-homepage:hover~.title-button {
    color: rgb(6, 245, 34);
}

.item-button img:hover {
    opacity: 0.5;
}

.toggle-language {
    align-self: center;
    width: 150px;
}

.icon-button {
    padding-right: 25px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;

    .icon {
        color: white;
        font-size: 30px;
        &:hover {
            color: #b3abab
        }
    }
}